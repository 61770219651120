import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css';

import Header from './components/header';
import Footer from './components/footer';
import FeedbackForm from './pages/FeedbackForm';

function App() {
  return (
    <>
    <Header></Header>
    <FeedbackForm></FeedbackForm>
      <Footer></Footer>
      </>
  );
}

export default App;
