function Footer() {
    return (
        <>
        <nav class="navbar fixed-bottom navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Sunbeam. © {(new Date()).getFullYear()}. All Rights Reserved.</a>
  </div>
</nav>
        </>
);
}

export default Footer;
