function FeedbackForm() {
    return (
        <>
        <br/>
        <div className="container">
          <fieldset className="border rounded border-info">
            <legend>Enter Email-Id OR Roll-No</legend>
          <div className="input-group mb-4">
          <span className="input-group-text">@</span>
  <input type="text" className="form-control" placeholder="Email-Id" aria-label="Email-Id" />
  <span className="input-group-text">Roll No</span>
  <input type="text" className="form-control" placeholder="Server" aria-label="Roll No" />    
<button className="btn-primary">Search</button>
</div>
</fieldset></div>
        </>
);
}

export default FeedbackForm;
